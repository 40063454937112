var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', _vm._b({
    staticClass: "v-data-table--default",
    attrs: {
      "hide-default-footer": "",
      "disable-filtering": "",
      "disable-sort": ""
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    }), {
      key: `item.hasUserAttended`,
      fn: function (_ref2) {
        var value = _ref2.value;
        return [value == undefined ? _c('span', [_vm._v(" - ")]) : value == true ? _c('span', {
          staticClass: "primary--text"
        }, [_vm._v(" 출석완료 ")]) : value == false ? _c('span', {
          staticClass: "pink--text text--lighten-1"
        }, [_vm._v(" 결석 ")]) : _vm._e()];
      }
    }], null, true)
  }, 'v-data-table', {
    items: _vm.items,
    headers: _vm.headers,
    loading: _vm.loading
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }