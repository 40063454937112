<template>
    <client-page>
        <template v-slot:subHead>
            <sub-visual title="마이페이지" src="/images/sub/sv-mypage.jpg"></sub-visual>
        </template>
        <template v-slot:contentsImmersive>
            <mypage-navigation-row />
        </template>

        <v-container>
            <v-data-table v-bind="{ items, headers }" hide-default-footer disable-filtering disable-sort class="v-data-table--default">
                <template #[`item.program.name`]="{ item, value }">
                    <router-link :to="`/program/items/${item._program}`">{{ value }}</router-link>
                </template>
                <template #[`item.program.status`]="{ item, value }">
                    <span v-if="item.program.status == '접수종료'" class="grey-8f--text">접수종료</span>
                    <span v-else-if="item.program.status == '모집마감'" class="grey-8f--text">모집마감</span>
                    <span v-else-if="item.program.status == '진행종료'" class="grey-8f--text">진행종료</span>
                    <span v-else-if="item.program.status == '접수중'" class="primary--text">접수중</span>
                    <span v-else-if="item.program.status == '진행중' && item.isApproved" class="green--text text--lighten-1">진행중</span>
                    <span v-else :class="stateColor(item) + '--text'">{{ value }}</span>
                </template>
                <template #[`item.state`]="{ item, value }">
                    <v-chip v-if="item.program.status == '진행종료'" x-small label color="grey" class="w-80px w-md-100px w-lg-120px">종료</v-chip>
                    <v-chip v-else-if="item.program.status == '진행중' && item.isApproved" x-small label color="green" class="w-80px w-md-100px w-lg-120px">진행중</v-chip>
                    <v-chip v-else x-small label dark :color="stateColor(item)" class="w-80px w-md-100px w-lg-120px">{{ PROGRAM_APPLICATION_STATES[value]?.text }}</v-chip>
                </template>
                <template #[`item.cancel`]="{ item }">
                    <v-btn :disabled="!isCancelable(item)" x-small color="pink lighten-1" class="w-80px w-md-100px w-lg-120px" @click="cancel(item)">신청취소</v-btn>
                </template>
                <template #[`item.attend`]="{ item }">
                    <v-btn v-if="isAttendable(item)" x-small dark color="green lighten-1" class="w-80px w-md-100px w-lg-120px" :to="`/mypage/programs/${item._program}`">출석확인</v-btn>
                </template>
                <template #footer> <pagination-component :value="page" :count="pageCount" @input="(page) => $router.push({ query: { page } })" /> </template>
            </v-data-table>
        </v-container>
    </client-page>
</template>

<script>
import api from "@/api";
import dayjs from "dayjs";
import { PROGRAM_APPLICATION_STATES } from "@/assets/variables";

import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import MypageNavigationRow from "@/components/client/mypage-navigation-row.vue";
import PaginationComponent from "@/components/client/control/pagination-component.vue";

let headers = [
    { text: "번호", value: "index", width: "5%", align: "center" },
    { text: "프로그램명", value: "program.name", width: "auto", align: "center" },
    { text: "기간", value: "program.period", width: "20%", align: "center" },
    { text: "요일/시간", value: "program.dayTime", width: "15%", align: "center" },
    { text: "상태", value: "program.status", width: "10%", align: "center" },
    { text: "신청", value: "state", width: "10%", align: "center" },
    { text: "취소", value: "cancel", width: "10%", align: "center" },
    { text: "출석", value: "attend", width: "10%", align: "center" },
];

export default {
    components: {
        ClientPage,
        SubVisual,
        MypageNavigationRow,
        PaginationComponent,
    },
    data: () => ({
        limit: 10,

        summary: { totalCount: 0 },
        applications: [],

        headers,
        PROGRAM_APPLICATION_STATES,
    }),
    computed: {
        items() {
            return this.applications.map((item, index) => ({ ...item, index: this.summary.totalCount - (this.page - 1) * this.limit - index }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
    },
    mounted() {
        this.reroute().then(this.search);
    },
    watch: {
        page() {
            this.search();
        },
    },
    methods: {
        async reroute() {
            if (!this.$store.state.accessToken) this.$router.push("/login");
        },
        async search() {
            let { skip, limit } = this;
            let { summary, applications } = await api.v1.me.programs.applications.gets({ _program: undefined, headers: { skip, limit } });

            this.summary = summary;
            this.applications = applications;
        },
        async cancel({ _program, _id, program }) {
            let go = confirm(`아래 프로그램에 대한 신청을 취소하시겠습니까?\n\n프로그램명: ${program?.name}`);
            if (go) {
                let { application } = await api.v1.me.programs.applications.putCancel({ _program, _id });
                this.update(application);
            }
        },
        update(item) {
            let index = this.applications.findIndex(({ _id }) => _id == item._id);
            if (index > -1) {
                this.applications.splice(index, 1, item);
            } else {
                this.applications.splice(0, 0, item);
            }
        },
        stateColor({ state }) {
            switch (state) {
                case PROGRAM_APPLICATION_STATES.APPLIED.value:
                    return "accent";
                case PROGRAM_APPLICATION_STATES.CANCELED.value:
                    return "secondary";
                case PROGRAM_APPLICATION_STATES.APPROVED.value:
                    return "primary";
                case PROGRAM_APPLICATION_STATES.REJECTED.value:
                    return "pink lighten-1";
            }
        },
        isCancelable(item) {
            if (item.state == PROGRAM_APPLICATION_STATES.CANCELED.value) return false;
            if (item.state == PROGRAM_APPLICATION_STATES.REJECTED.value) return false;
            if (dayjs().isAfter(item.program.applicationEndsAt, "date")) return false;
            return true;
        },
        isAttendable(item) {
            return item.state == PROGRAM_APPLICATION_STATES.APPROVED.value;
        },
    },
};
</script>
