var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "app--mypage",
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "title": "마이페이지",
            "src": "/images/sub/sv-mypage.jpg"
          }
        })];
      },
      proxy: true
    }, {
      key: "contentsImmersive",
      fn: function () {
        return [_c('mypage-navigation-row')];
      },
      proxy: true
    }])
  }, [_c('v-container', [_c('router-view', {
    attrs: {
      "name": "question"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }