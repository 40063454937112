<template>
    <v-card>
        <div class="mypage-programs">
            <div class="pa-20 px-md-36 py-md-30">
                <div class="mb-10 mb-md-12">
                    <v-row align="center" class="row--small">
                        <v-col cols="auto">
                            <h3 class="tit tit--sm">추천프로그램</h3>
                        </v-col>
                        <v-spacer />
                        <v-col cols="auto">
                            <div class="swiper-pagination"></div>
                        </v-col>
                    </v-row>
                </div>

                <div class="swiper-container">
                    <div class="swiper-wrapper">
                        <div v-for="(program, index) in programs" :key="index" class="swiper-slide">
                            <v-card elevation="0" to="/" class="overflow-hidden">
                                <div class="mypage-programs__inner">
                                    <v-img :aspect-ratio="210/298" :src="program.thumb?.url">
                                        <template #placeholder>
                                            <v-overlay absolute z-index="0" opacity=".1">
                                                <v-icon>mdi-image-off</v-icon>
                                            </v-overlay>
                                        </template>
                                        <div class="mypage-programs__con">
                                            <span class="mypage-programs__label">{{ program?.category.name }}</span>
                                            <h3 class="mypage-programs__tit">{{ program?.name }}</h3>
                                        </div>
                                    </v-img>
                                </div>
                            </v-card>
                        </div>
                    </div>
                    <div class="swiper-control">
                        <div class="swiper-prev"><i class="icon icon-chevron-left-sm"></i></div>
                        <div class="swiper-next"><i class="icon icon-chevron-right-sm"></i></div>
                    </div>
                </div>
            </div>
        </div>
    </v-card>
</template>

<script>
import api from "@/api";
import Swiper from "swiper/bundle";

export default {
    props: {
        keywords: { type: Array, default: () => [] },
    },
    data: () => ({
        selectedIndex: 0,
        programs: [],
        limit: 12,
        itemsPerSlide: 3,
    }),
    computed: {
        pageCount() {
            return Math.ceil(this.programs.length / this.itemsPerSlide);
        },
        slides() {
            return new Array(this.pageCount).fill([]).map((items, i) => items.concat(this.programs.slice(i * this.itemsPerSlide, (i + 1) * this.itemsPerSlide)));
        },
        cols() {
            let cols = 12 % this.itemsPerSlide ? undefined : 12 / this.itemsPerSlide;
            if (!cols || cols > 4) cols = 4;
            return cols;
        },
    },
    mounted() {
        if (this.keywords) this.init();

        this.$nextTick(() => {
            setTimeout(() => {
                this.swiper = new Swiper(".mypage-programs .swiper-container", {
                    allowTouchMove: true,
                    centeredSlides: true,
                    slidesPerView: 2,
                    spaceBetween: 8,
                    autoplay: {
                        delay: 5000,
                        disableOnInteraction: false,
                    },
                    speed: 500,
                    loop: false,
                    pagination: {
                        el: ".mypage-programs .swiper-pagination",
                        clickable: true,
                    },
                    navigation: {
                        prevEl: ".mypage-programs .swiper-prev",
                        nextEl: ".mypage-programs .swiper-next",
                    },
                    breakpoints: {
                        576: {
                            slidesPerView: 2,
                            spaceBetween: 8,
                        },
                        768: {
                            centeredSlides: false,
                            slidesPerView: 3,
                            spaceBetween: 10,
                        },
                        1024: {
                            centeredSlides: false,
                            slidesPerView: 3,
                            spaceBetween: 14,
                        },
                    },
                });
            }, 100);
        });
    },
    watch: {
        keywords() {
            if (this.keywords) this.init();
        },
    },
    methods: {
        async init() {
            let { limit, keywords } = this;
            let { programs } = await api.v1.programs.gets({
                headers: { limit },
                params: { keywords },
            });
            this.programs = programs;
        },
    },
};
</script>

<style scoped>
/* mypage-programs swiper */
.mypage-programs .swiper-container{
    padding: 0 14px;
    margin: 0 -14px;
}
.mypage-programs .swiper-container::before,
.mypage-programs .swiper-container::after{
    content:'';
    position:absolute;
    top:0;
    display: block;
    width: 14px;
    height: 100%;
    background-color: #fff;
}
.mypage-programs .swiper-container::before{
    z-index: 2;
    left:0;
}
.mypage-programs .swiper-container::after{
    z-index: 1;
    right:0;
}
.mypage-programs .swiper-slide{
    width: 50%;
}
.mypage-programs .swiper-control{
    z-index: 2;
}
.mypage-programs .swiper-prev,
.mypage-programs .swiper-next{
    width: 34px;
    height: 34px;
    border: 1px solid var(--border-color);
    background-color: #fff;
}
.mypage-programs .swiper-prev{
    left:0;
}
.mypage-programs .swiper-next{
    right:0;
}
.mypage-programs .swiper-button-disabled{
    opacity: .4;
}
.mypage-programs .swiper-pagination{
    position: static;
    left: auto;
    bottom: auto;
    transform: none;
}
@media (min-width:576px){
}
@media (min-width:768px){
    .mypage-programs .swiper-slide{
        width: 33.3333%;
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

/* mypage-programs */
.mypage-programs__inner{
    position: relative;
}
.mypage-programs__con {
    position: absolute;
    left:0;
    bottom:0;
    width: 100%;
    color: #fff;
    padding: 12px;
    background-image: -webkit-linear-gradient(to top, var(--v-primary-base), transparent);
    background-image: -ms-linear-gradient(to top, var(--v-primary-base), transparent);
    background-image: linear-gradient(to top, var(--v-primary-base), transparent);
}
.mypage-programs__label{
    display: inline-flex;
    align-items: center;
    height: 20px;
    font-size: 1.4rem;
    color:#fff;
    background-color: var(--v-primary-base);
    border-radius: 6px;
    padding: 0 6px;
    margin-bottom: 4px;
}
.mypage-programs__tit {
    font-size: 1.6rem;
    font-weight: 700;
    max-height: 2.6em;
    line-height: 1.3;
    overflow: hidden; text-align: left; text-overflow: ellipsis; white-space: normal; word-wrap: break-word; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .mypage-programs__con {
        padding: 14px;
    }
    .mypage-programs__label{
        padding: 0 8px;
        margin-bottom: 6px;
    }
    .mypage-programs__tit {
        font-size: 1.8rem;
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>