<template>
    <v-row align="center" class="row--small">
        <v-col cols="auto"><strong class="font-size-16 font-size-md-18 font-size-lg-20">관심키워드</strong></v-col>
        <v-col>
            <v-row align="center" class="row--small">
                <v-col cols="auto" v-for="keyword in keywords" :key="keyword._id">
                    <span class="font-size-18 font-size-md-20 font-size-lg-28 font-weight-medium primary--text">#{{ keyword.name }}</span>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        keywords: { type: Array, default: () => [] },
    },
};
</script>

<style>
</style>