<template>
    <nav class="mypage-navigation-container">
        <div class="mypage-navigation__tit">MYPAGE</div>
        <ul class="mypage-navigation">
            <li class="mypage-navigation__list">
                <router-link to="/mypage/bids-all" exact-active-class="" active-class="on" class="mypage-navigation__link"><span>응찰내역</span></router-link>
            </li>
            <li class="mypage-navigation__list">
                <router-link to="/mypage/bids-won" exact-active-class="" active-class="on" class="mypage-navigation__link"><span>낙찰내역</span></router-link>
            </li>
            <li class="mypage-navigation__list">
                <router-link to="/mypage/private-list" exact-active-class="" active-class="on" class="mypage-navigation__link"><span>작품문의내역</span></router-link>
            </li>
            <li class="mypage-navigation__list">
                <router-link to="/mypage/consignment-list" exact-active-class="" active-class="on" class="mypage-navigation__link"><span>위탁신청내역</span></router-link>
            </li>
            <li class="mypage-navigation__list">
                <router-link to="/mypage/consignment-form" exact-active-class="" active-class="on" class="mypage-navigation__link"><span>위탁신청</span></router-link>
            </li>
            <li class="mypage-navigation__list">
                <router-link to="/mypage/question" exact-active-class="" active-class="on" class="mypage-navigation__link"><span>1:1문의</span></router-link>
            </li>
            <li class="mypage-navigation__list">
                <router-link to="/mypage/myinfo" exact-active-class="" active-class="on" class="mypage-navigation__link"><span>회원정보수정</span></router-link>
            </li>
            <li class="mypage-navigation__list">
                <router-link to="/mypage/leave" exact-active-class="" active-class="on" class="mypage-navigation__link"><span>회원탈퇴</span></router-link>
            </li>
        </ul>
    </nav>
</template>
<script>
export default{
    data() {
        return{
            //  화살표 아이콘
            icon: '<svg x="0px" y="0px" viewBox="0 0 9.7 6.4" style="fill:none;stroke:#333333;stroke-width:2;stroke-linecap:round;"><path d="M1,1.4l3.8,4l3.9-4"/></svg>',
        }
    },
    methods : {
        accordionToggle(event){
            var button = event.currentTarget,
                contents = button.nextSibling;
            var height = 0;

            if(button.getAttribute('data-toggle') === 'closed'){
                contents.style.maxHeight = 'none';
                height = contents.clientHeight;
                contents.style.maxHeight = '0';
                setTimeout(function(){ contents.style.maxHeight = height + 'px'; }, 1);
                button.setAttribute('data-toggle', 'opened');
                contents.ontransitionend = () => {
                    contents.removeAttribute('style');
                };
            }else{
                height = contents.clientHeight;
                contents.style.maxHeight = height + 'px';
                setTimeout(function(){ contents.style.maxHeight = '0px'; }, 1);
                button.setAttribute('data-toggle', 'closed');
                contents.ontransitionend = () => {
                    contents.removeAttribute('style');
                };
            }
        }
    },
    computed: {
        payload(){
            return this.$store.state.payload || {};
        },
        scope(){
            return this.payload.scope || [];
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";

.mypage-navigation{
    list-style: none;
    .on {
        position: relative;
        font-weight: 600;
        color: var(--v-primary-base);
        }
    &-container { padding-right: 50px; width: 240px; }
	&__link {
        display: block;
        position: relative;
        padding: 8px 0;
        font-size: 1.6rem;
        line-height: 1.2;
        color: #686868;
        cursor: pointer;
        user-select: none;
    }
    &__link[data-toggle] { user-select: none; }
	&__link[data-toggle] .arrow { display: flex; align-items: center; justify-content: center; position: absolute; top: 0; right: 0; bottom: 0; margin: auto; width: 11px; height: 20px; }
    &__link[data-toggle] .arrow::v-deep svg { display: block; width: 11px; height: 7px; pointer-events: none; }
	&__link[data-toggle="opened"] { border-bottom-style: dashed; }
	&__link[data-toggle="opened"] .arrow { -webkit-transform: scaleY(-1); transform: scaleY(-1); }
	&__link[data-toggle="closed"] + &__sub { max-height: 0; }
    &__sub { overflow: hidden; transition: all ease .2s; }
	&__level{
        list-style: none;
        &--1 { padding: 15px 0 5px; border-bottom: 1px solid #dddddd; }
        &-link { display: block; padding: 0 10px; height: 30px; line-height: 1; font-size: 18px; color: #555555; font-weight: 300; cursor: pointer; user-select: none; }
        &-link[data-toggle="opened"] { color: var(--v-primary-base); font-weight: 700; }
        &-link[data-toggle="opened"] + .mypage-navigation__level { display: block; }
    }
}
.mypage-navigation__tit{
    font-size: 3rem;
    font-weight: 500;
    color: var(--v-primary-base);
    margin-bottom: 16px;
}
@media(max-width:1024px){
    .mypage-navigation{
        display: flex; scrollbar-width: none; overflow-x: auto; overflow-y: hidden;
        position: relative; white-space: nowrap;
        border-bottom:1px solid var(--border-color);
        .on { border-color: var(--v-primary-base); }
        .on::before { content: ''; position: absolute; bottom: 0; left: 0; width: 100%; height: 1px; background: var(--v-primary-base); }
        &-container { padding: 0 0 35px; width: 100%; }
        &::-webkit-scrollbar { display: none; }
        &__list { flex: 1 1 auto; }
        &__link {
            font-size: 1.4rem;
            text-align: center;
            padding: 12px;
        }
        &__list-link { margin-right: 18px; padding: 18px 0 0; height: 50px; font-size: 14px; color: #777777; font-weight: 400; border-bottom: none; }
        &__list-link span { display: block; padding-bottom: 8px; border-bottom: 1px solid transparent; }
        &__list-link[data-toggle] .arrow { display: none; }
        &__list-link[data-toggle="opened"] { border-bottom: none; color: #333333; font-weight: 500; }
        &__list-link[data-toggle="opened"] span { border-bottom-color: #333333; }
        &__level{
            &--1 { display: none; align-items: center; position: absolute; bottom: 0; left: 0; width: 100%; height: 50px; }
            &--1.active { display: flex; }
            &-link { margin-right: 18px; padding: 0; font-size: 14px; }
        }
    }
    .mypage-navigation__tit{
        font-size: 2.4rem;
        margin-bottom: 0;
    }
}
</style>
