var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('div', {
    staticClass: "mypage-programs"
  }, [_c('div', {
    staticClass: "pa-20 px-md-36 py-md-30"
  }, [_c('div', {
    staticClass: "mb-10 mb-md-12"
  }, [_c('v-row', {
    staticClass: "row--small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('h3', {
    staticClass: "tit tit--sm"
  }, [_vm._v("추천프로그램")])]), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "swiper-pagination"
  })])], 1)], 1), _c('div', {
    staticClass: "swiper-container"
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, _vm._l(_vm.programs, function (program, index) {
    var _program$thumb;
    return _c('div', {
      key: index,
      staticClass: "swiper-slide"
    }, [_c('v-card', {
      staticClass: "overflow-hidden",
      attrs: {
        "elevation": "0",
        "to": "/"
      }
    }, [_c('div', {
      staticClass: "mypage-programs__inner"
    }, [_c('v-img', {
      attrs: {
        "aspect-ratio": 210 / 298,
        "src": (_program$thumb = program.thumb) === null || _program$thumb === void 0 ? void 0 : _program$thumb.url
      },
      scopedSlots: _vm._u([{
        key: "placeholder",
        fn: function () {
          return [_c('v-overlay', {
            attrs: {
              "absolute": "",
              "z-index": "0",
              "opacity": ".1"
            }
          }, [_c('v-icon', [_vm._v("mdi-image-off")])], 1)];
        },
        proxy: true
      }], null, true)
    }, [_c('div', {
      staticClass: "mypage-programs__con"
    }, [_c('span', {
      staticClass: "mypage-programs__label"
    }, [_vm._v(_vm._s(program === null || program === void 0 ? void 0 : program.category.name))]), _c('h3', {
      staticClass: "mypage-programs__tit"
    }, [_vm._v(_vm._s(program === null || program === void 0 ? void 0 : program.name))])])])], 1)])], 1);
  }), 0), _c('div', {
    staticClass: "swiper-control"
  }, [_c('div', {
    staticClass: "swiper-prev"
  }, [_c('i', {
    staticClass: "icon icon-chevron-left-sm"
  })]), _c('div', {
    staticClass: "swiper-next"
  }, [_c('i', {
    staticClass: "icon icon-chevron-right-sm"
  })])])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }