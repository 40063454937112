var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('nav', {
    staticClass: "mypage-navigation-container"
  }, [_c('div', {
    staticClass: "mypage-navigation__tit"
  }, [_vm._v("MYPAGE")]), _c('ul', {
    staticClass: "mypage-navigation"
  }, [_c('li', {
    staticClass: "mypage-navigation__list"
  }, [_c('router-link', {
    staticClass: "mypage-navigation__link",
    attrs: {
      "to": "/mypage/bids-all",
      "exact-active-class": "",
      "active-class": "on"
    }
  }, [_c('span', [_vm._v("응찰내역")])])], 1), _c('li', {
    staticClass: "mypage-navigation__list"
  }, [_c('router-link', {
    staticClass: "mypage-navigation__link",
    attrs: {
      "to": "/mypage/bids-won",
      "exact-active-class": "",
      "active-class": "on"
    }
  }, [_c('span', [_vm._v("낙찰내역")])])], 1), _c('li', {
    staticClass: "mypage-navigation__list"
  }, [_c('router-link', {
    staticClass: "mypage-navigation__link",
    attrs: {
      "to": "/mypage/private-list",
      "exact-active-class": "",
      "active-class": "on"
    }
  }, [_c('span', [_vm._v("작품문의내역")])])], 1), _c('li', {
    staticClass: "mypage-navigation__list"
  }, [_c('router-link', {
    staticClass: "mypage-navigation__link",
    attrs: {
      "to": "/mypage/consignment-list",
      "exact-active-class": "",
      "active-class": "on"
    }
  }, [_c('span', [_vm._v("위탁신청내역")])])], 1), _c('li', {
    staticClass: "mypage-navigation__list"
  }, [_c('router-link', {
    staticClass: "mypage-navigation__link",
    attrs: {
      "to": "/mypage/consignment-form",
      "exact-active-class": "",
      "active-class": "on"
    }
  }, [_c('span', [_vm._v("위탁신청")])])], 1), _c('li', {
    staticClass: "mypage-navigation__list"
  }, [_c('router-link', {
    staticClass: "mypage-navigation__link",
    attrs: {
      "to": "/mypage/question",
      "exact-active-class": "",
      "active-class": "on"
    }
  }, [_c('span', [_vm._v("1:1문의")])])], 1), _c('li', {
    staticClass: "mypage-navigation__list"
  }, [_c('router-link', {
    staticClass: "mypage-navigation__link",
    attrs: {
      "to": "/mypage/myinfo",
      "exact-active-class": "",
      "active-class": "on"
    }
  }, [_c('span', [_vm._v("회원정보수정")])])], 1), _c('li', {
    staticClass: "mypage-navigation__list"
  }, [_c('router-link', {
    staticClass: "mypage-navigation__link",
    attrs: {
      "to": "/mypage/leave",
      "exact-active-class": "",
      "active-class": "on"
    }
  }, [_c('span', [_vm._v("회원탈퇴")])])], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }