var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "title": "마이페이지",
            "src": "/images/sub/sv-mypage.jpg"
          }
        })];
      },
      proxy: true
    }, {
      key: "contentsImmersive",
      fn: function () {
        return [_c('mypage-navigation-row')];
      },
      proxy: true
    }])
  }, [_c('v-container', [_c('program-view-card', _vm._b({
    attrs: {
      "hideButton": ""
    }
  }, 'program-view-card', {
    program: _vm.program
  }, false)), _c('div', {
    staticClass: "tit-wrap--dot mb-0"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("행사일정")])]), _c('v-divider', {
    staticClass: "my-14 my-md-24"
  }), _c('program-view-attendances', _vm._b({}, 'program-view-attendances', {
    program: _vm.program
  }, false)), _c('div', {
    staticClass: "mt-12 mt-md-22"
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "justify": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-140px",
    attrs: {
      "outlined": "",
      "color": "grey-d6"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("목록")])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }