var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "title": "마이페이지",
            "src": "/images/sub/sv-mypage.jpg"
          }
        })];
      },
      proxy: true
    }, {
      key: "contentsImmersive",
      fn: function () {
        return [_c('mypage-navigation-row')];
      },
      proxy: true
    }])
  }, [_c('v-container', [_c('v-data-table', _vm._b({
    staticClass: "v-data-table--default",
    attrs: {
      "hide-default-footer": "",
      "disable-filtering": "",
      "disable-sort": ""
    },
    scopedSlots: _vm._u([{
      key: `item.program.name`,
      fn: function (_ref) {
        var item = _ref.item,
          value = _ref.value;
        return [_c('router-link', {
          attrs: {
            "to": `/program/items/${item._program}`
          }
        }, [_vm._v(_vm._s(value))])];
      }
    }, {
      key: `item.program.status`,
      fn: function (_ref2) {
        var item = _ref2.item,
          value = _ref2.value;
        return [item.program.status == '접수종료' ? _c('span', {
          staticClass: "grey-8f--text"
        }, [_vm._v("접수종료")]) : item.program.status == '모집마감' ? _c('span', {
          staticClass: "grey-8f--text"
        }, [_vm._v("모집마감")]) : item.program.status == '진행종료' ? _c('span', {
          staticClass: "grey-8f--text"
        }, [_vm._v("진행종료")]) : item.program.status == '접수중' ? _c('span', {
          staticClass: "primary--text"
        }, [_vm._v("접수중")]) : item.program.status == '진행중' && item.isApproved ? _c('span', {
          staticClass: "green--text text--lighten-1"
        }, [_vm._v("진행중")]) : _c('span', {
          class: _vm.stateColor(item) + '--text'
        }, [_vm._v(_vm._s(value))])];
      }
    }, {
      key: `item.state`,
      fn: function (_ref3) {
        var _vm$PROGRAM_APPLICATI;
        var item = _ref3.item,
          value = _ref3.value;
        return [item.program.status == '진행종료' ? _c('v-chip', {
          staticClass: "w-80px w-md-100px w-lg-120px",
          attrs: {
            "x-small": "",
            "label": "",
            "color": "grey"
          }
        }, [_vm._v("종료")]) : item.program.status == '진행중' && item.isApproved ? _c('v-chip', {
          staticClass: "w-80px w-md-100px w-lg-120px",
          attrs: {
            "x-small": "",
            "label": "",
            "color": "green"
          }
        }, [_vm._v("진행중")]) : _c('v-chip', {
          staticClass: "w-80px w-md-100px w-lg-120px",
          attrs: {
            "x-small": "",
            "label": "",
            "dark": "",
            "color": _vm.stateColor(item)
          }
        }, [_vm._v(_vm._s((_vm$PROGRAM_APPLICATI = _vm.PROGRAM_APPLICATION_STATES[value]) === null || _vm$PROGRAM_APPLICATI === void 0 ? void 0 : _vm$PROGRAM_APPLICATI.text))])];
      }
    }, {
      key: `item.cancel`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('v-btn', {
          staticClass: "w-80px w-md-100px w-lg-120px",
          attrs: {
            "disabled": !_vm.isCancelable(item),
            "x-small": "",
            "color": "pink lighten-1"
          },
          on: {
            "click": function ($event) {
              return _vm.cancel(item);
            }
          }
        }, [_vm._v("신청취소")])];
      }
    }, {
      key: `item.attend`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_vm.isAttendable(item) ? _c('v-btn', {
          staticClass: "w-80px w-md-100px w-lg-120px",
          attrs: {
            "x-small": "",
            "dark": "",
            "color": "green lighten-1",
            "to": `/mypage/programs/${item._program}`
          }
        }, [_vm._v("출석확인")]) : _vm._e()];
      }
    }, {
      key: "footer",
      fn: function () {
        return [_c('pagination-component', {
          attrs: {
            "value": _vm.page,
            "count": _vm.pageCount
          },
          on: {
            "input": function (page) {
              return _vm.$router.push({
                query: {
                  page
                }
              });
            }
          }
        })];
      },
      proxy: true
    }], null, true)
  }, 'v-data-table', {
    items: _vm.items,
    headers: _vm.headers
  }, false))], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }