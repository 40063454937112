<template>
    <div>
        <div class="tit-wrap">
            <h2 class="tit tit--xl"><span class="primary--text">{{ user.name }}</span>님의 방문을 환영합니다</h2>
        </div>
        <dashboard-header-categories v-bind="user" />

        <div class="mt-30 mt-md-40 mt-lg-50">
             <v-row>
                <v-col cols="12" lg="6">
                    <dashboard-header-programs v-bind="user" />
                </v-col>
                <v-col cols="12" lg="6">
                    <dashboard-header-navigation />
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import DashboardHeaderPrograms from "./dashboard-header-programs.vue";
import DashboardHeaderCategories from "./dashboard-header-categories.vue";
import DashboardHeaderNavigation from "./dashboard-header-navigation.vue";
export default {
    components: {
        DashboardHeaderPrograms,
        DashboardHeaderCategories,
        DashboardHeaderNavigation,
    },
    props: {
        user: { type: Object, default: () => ({}) },
    },
};
</script>

<style>
</style>