var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "row--small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('strong', {
    staticClass: "font-size-16 font-size-md-18 font-size-lg-20"
  }, [_vm._v("관심키워드")])]), _c('v-col', [_c('v-row', {
    staticClass: "row--small",
    attrs: {
      "align": "center"
    }
  }, _vm._l(_vm.keywords, function (keyword) {
    return _c('v-col', {
      key: keyword._id,
      attrs: {
        "cols": "auto"
      }
    }, [_c('span', {
      staticClass: "font-size-18 font-size-md-20 font-size-lg-28 font-weight-medium primary--text"
    }, [_vm._v("#" + _vm._s(keyword.name))])]);
  }), 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }