var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "row--small row--dashboard-header-navigation"
  }, _vm._l(_vm.items, function (item) {
    return _c('v-col', {
      key: item.to,
      attrs: {
        "cols": "12",
        "sm": "6"
      }
    }, [_c('v-card', {
      attrs: {
        "height": "100%",
        "to": item.to
      }
    }, [_c('div', {
      staticClass: "d-flex flex-column h-100 pa-20 px-md-36 py-md-30"
    }, [_c('div', {
      staticClass: "mb-4"
    }, [_c('v-row', {
      staticClass: "row--x-small",
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('div', {
      staticClass: "tit tit--sm"
    }, [_vm._v(_vm._s(item.text))])]), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('i', {
      staticClass: "icon icon-chevron-right-sm"
    })])], 1)], 1), _c('v-img', _vm._b({
      staticClass: "mt-auto ml-auto"
    }, 'v-img', item, false))], 1)])], 1);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }