<template>
    <v-data-table v-bind="{ items, headers, loading }" hide-default-footer disable-filtering disable-sort class="v-data-table--default">
        <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" v-slot:[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
        <template #[`item.hasUserAttended`]="{ value }">
            <span v-if="value == undefined"> - </span>
            <span v-else-if="value == true" class="primary--text"> 출석완료 </span>
            <span v-else-if="value == false" class="pink--text text--lighten-1"> 결석 </span>
        </template>
    </v-data-table>
</template>

<script>
import dayjs from "dayjs";
import api from "@/api";
let headers = [
    { text: "회차", value: "number", formatter: (value) => `${value}회차` },
    { text: "수업날짜", value: "date", formatter: (value) => value.toDate() },
    { text: "시간", value: "time" },
    { text: "출석여부", value: "hasUserAttended" },
];

export default {
    props: {
        program: { type: Object },
    },
    data: () => ({
        headers,

        loading: false,
        attendances: [],
    }),
    computed: {
        items() {
            return (this.program?.units || []).map((item, index) => {
                let hasUserAttended = (this.attendances || []).some(({ _unit }) => _unit == item._id);
                if (dayjs().isBefore(item.endsAt) && hasUserAttended == false) {
                    hasUserAttended = undefined;
                }
                return {
                    ...item,
                    number: index + 1,
                    hasUserAttended,
                };
            });
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        "program._id"() {
            this.init();
        },
    },
    methods: {
        async init() {
            let { _id: _program } = this.program || {};
            if (_program) {
                this.loading = true;
                try {
                    this.attendances = (await api.v1.me.programs.attendances.gets({ _program }))?.attendances;
                } catch (error) {
                    console.error(error);
                } finally {
                    this.loading = false;
                }
            }
        },
    },
};
</script>

<style>
</style>