<template>
    <client-page class="app--mypage">
        <page-section class="page-section--first page-section--py primary lighten-5">
            <dashboard-header v-bind="{ user }" />
        </page-section>

        <page-section class="page-section--last">
            <dashboard-calendar v-bind="{ user }" />
        </page-section>
    </client-page>
</template>

<script>
import api from "@/api";

import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/dumb/page-section.vue";
import DashboardHeader from "@/components/client/mypage/dashboard-header.vue";
import DashboardCalendar from "@/components/client/mypage/dashboard-calendar.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        DashboardHeader,
        DashboardCalendar,
    },
    data: () => ({
        user: {},
    }),
    mounted() {
        this.reroute().then(this.init);
    },
    methods: {
        async reroute() {
            if (!this.$store.state.accessToken) this.$router.push("/login");
            let path = "/mypage/dashboard";
            if (this.$route.path != path) this.$router.replace({ path });
        },
        async init() {
            this.user = (await api.v1.me.get())?.user;
        },
    },
};
</script>
