var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "tit-wrap"
  }, [_c('h2', {
    staticClass: "tit tit--xl"
  }, [_c('span', {
    staticClass: "primary--text"
  }, [_vm._v(_vm._s(_vm.user.name))]), _vm._v("님의 방문을 환영합니다")])]), _c('dashboard-header-categories', _vm._b({}, 'dashboard-header-categories', _vm.user, false)), _c('div', {
    staticClass: "mt-30 mt-md-40 mt-lg-50"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('dashboard-header-programs', _vm._b({}, 'dashboard-header-programs', _vm.user, false))], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('dashboard-header-navigation')], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }