<template>
    <div>
        <dashboard-calendar-heading v-bind="{ user }" />

        <div class="mt-30 mt-md-40 mt-lg-50" style="position: relative">
            <v-sheet color="primary lighten-4 rounded-t pa-12 pa-md-16">
                <v-row no-gutters justify="center" align="center">
                    <v-col cols="auto">
                        <div class="border rounded-circle">
                            <v-btn small fab color="white" @click="goPreviousMonth"><i class="icon icon-chevron-left"></i></v-btn>
                        </div>
                    </v-col>
                    <v-col cols="auto">
                        <strong class="tit px-30 px-md-50">{{ month }}월</strong>
                    </v-col>
                    <v-col cols="auto">
                        <div class="border rounded-circle">
                            <v-btn small fab color="white" @click="goNextMonth"><i class="icon icon-chevron-right"></i></v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-sheet>
            <v-calendar v-model="date" v-bind="{ events, dayFormat }" category-show-all :show-month-on-first="false" @click:event="({ event: { to } }) => (to ? $router.push(to) : null)" />
            <v-overlay v-show="loading" absolute color="white" light>
                <v-progress-circular indeterminate width="5" size="100" color="black" />
            </v-overlay>
        </div>
    </div>
</template>

<script>
import dayjs from "dayjs";
import DashboardCalendarHeading from "./dashboard-calendar-heading.vue";
import api from "@/api";
import { RENTAL_SCHEDULE_STATES } from "@/assets/variables";

let dayFormat = ({ date }) => dayjs(date).get("date");
export default {
    components: {
        DashboardCalendarHeading,
    },
    props: {
        user: { type: Object, default: () => ({}) },
    },
    data: () => ({
        loading: false,

        date: Date.now().toDate(),
        units: [],
        schedules: [],
    }),
    computed: {
        month() {
            return dayjs(this.date).month() + 1;
        },
        dates() {
            let start = dayjs(this.date).startOf("month");
            let end = dayjs(this.date).endOf("month");
            return [start, end].map((date) => date.toISOString());
        },
        events() {
            if (this.loading) return [];
            return [
                ...this.units.map((unit) => ({
                    name: unit?.program?.name,
                    start: new Date(unit?.startsAt),
                    end: new Date(unit?.endsAt),
                    color: "primary",
                    to: `/mypage/programs/${unit?._program}`,
                })),
                ...this.schedules.map((schedule) => ({
                    name: schedule?.space?.name,
                    start: new Date(schedule?.datetime),
                    end: dayjs(schedule?.datetime).add(1, "hour").toDate(),
                    color: "green lighten-1",
                })),
            ].map((event) => ({ ...event, timed: true }));
        },
    },
    mounted() {
        this.search();
    },
    watch: {
        dates() {
            this.search();
        },
    },
    methods: {
        dayFormat,
        goPreviousMonth() {
            this.date = dayjs(this.date).startOf("month").subtract(1, "month").toISOString().toDate();
        },
        goNextMonth() {
            this.date = dayjs(this.date).startOf("month").add(1, "month").toISOString().toDate();
        },
        async search() {
            this.loading = true;
            try {
                let { dates } = this;
                this.units = (await api.v1.me.programUnits.gets({ params: { dates } }))?.units;
                this.schedules = (await api.v1.me.rentalSchedules.gets({ params: { dates, state: RENTAL_SCHEDULE_STATES.APPROVED.value } }))?.schedules;
            } catch (error) {
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style scoped>
>>> .v-calendar-weekly,
>>> .v-calendar-weekly__head-weekday {
    font-size: 1.6rem;
}
>>> .v-calendar-weekly {
    border: 0;
}
>>> .v-calendar-weekly__head {
    border: 2px solid var(--v-primary-lighten4);
}
>>> .v-calendar-weekly__head-weekday {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    font-weight: 500;
}
>>> .v-calendar-weekly__head-weekday:first-child,
>>> .v-calendar-weekly__day:first-child .v-btn__content {
    color: #d61a43 !important;
}
>>> .v-calendar-weekly__day.v-outside,
>>> .v-calendar-weekly__day.v-outside .v-btn__content {
    color: #ccc !important;
}
>>> .v-calendar-weekly__day.v-outside .v-btn:before {
    opacity: 0;
}
>>> .theme--light.v-calendar-weekly .v-calendar-weekly__head-weekday,
>>> .theme--light.v-calendar-weekly .v-calendar-weekly__day {
    color: var(--default-text-color);
    background-color: #fff;
    border: 0;
}
>>> .v-calendar.v-calendar-events .v-calendar-weekly__head-weekday {
    margin-right: 0;
}
>>> .v-calendar-weekly__day {
    min-height: 100px;
    padding: 6px;
}
>>> .theme--light.v-calendar-weekly .v-calendar-weekly__day {
    border-left: 2px solid var(--v-primary-lighten4);
    border-bottom: 2px solid var(--v-primary-lighten4);
}
>>> .theme--light.v-calendar-weekly .v-calendar-weekly__day:first-child {
    border-left: 0;
}
>>> .v-calendar-weekly__day-label {
    text-align: right;
    margin: 0;
}
>>> .v-calendar .v-event,
>>> .v-calendar .v-event-more {
    display: flex;
    align-items: center;
    width: 100% !important;
    height: 26px !important;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1;
    padding: 0 6px;
    margin: 4px 0 0 !important;
}
>>> .v-calendar .v-event strong,
>>> .v-calendar .v-event-more strong {
    font-weight: 600;
}
>>> .v-calendar .v-event > div,
>>> .v-calendar .v-event-more > div {
    width: 100%;
}
>>> .v-event-summary {
    display: block;
    width: 100%;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    >>> .v-calendar-weekly,
    >>> .v-calendar-weekly__head-weekday {
        font-size: 1.8rem;
    }
    >>> .v-calendar-weekly__day {
        min-height: 170px;
    }
    >>> .v-calendar-weekly__day {
        padding: 8px;
    }
    >>> .v-calendar .v-event,
    >>> .v-calendar .v-event-more {
        height: 30px !important;
        font-size: 1.8rem;
        padding: 0 10px;
    }
}
@media (min-width: 1024px) {
    >>> .v-calendar-weekly,
    >>> .v-calendar-weekly__head-weekday {
        font-size: 2rem;
    }
}
@media (min-width: 1200px) {
}
</style>
