<template>
    <client-page class="app--mypage primary lighten-5">
        <v-container>
            <div class="tit-wrap text-center">
                <h2 class="tit tit--xl">회원탈퇴</h2>
            </div>

            <v-tabs-items :value="tab">
                <!-- S:안내탭 -->
                <v-tab-item value="info">
                    <v-card max-width="550" class="ma-auto">
                        <div class="pa-30 px-lg-50 pt-lg-70 pb-lg-60">
                            <div class="tit-wrap mb-12 justify-center">
                                <h4 class="tit--sm">탈퇴하신 계정은 복구되지 않습니다.</h4>
                            </div>

                            <div class="mt-20 mt-md-30">
                                <v-btn block large color="primary" to="/mypage/leave/action">회원탈퇴</v-btn>
                            </div>
                        </div>
                    </v-card>
                </v-tab-item>
                <!-- E:안내탭 -->

                <!-- S:인풋탭 -->
                <v-tab-item value="action">
                    <div class="tit-wrap mb-12">
                        <h3 class="tit--sm">탈퇴정보</h3>
                    </div>

                    <div class="table-style table-style--default">
                        <myinfo-modify-row label="탈퇴 사유">
                            <v-text-field v-model="withdrawalReason" type="text" outlined hide-details />
                        </myinfo-modify-row>
                        <myinfo-modify-row label="남기실 말씀">
                            <v-textarea v-model="withdrawalMessage" outlined persistent-placeholder hide-details />
                        </myinfo-modify-row>
                    </div>

                    <!-- 하단버튼 -->
                    <div class="v-btn-group--bottom">
                        <v-row justify="center" class="row--small">
                            <v-col cols="6" sm="auto">
                                <v-btn large outlined block exact color="grey-d6" class="w-md-240px" to="/mypage/leave">취소</v-btn>
                            </v-col>
                            <v-col cols="6" sm="auto">
                                <v-btn large block color="primary" class="w-md-240px" @click="withdraw">탈퇴신청</v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-tab-item>
                <!-- E:인풋탭 -->
            </v-tabs-items>
        </v-container>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import MyinfoModifyRow from "@/components/client/mypage/myinfo-modify-row.vue";
import MypageNavigation from "../../../components/client/mypage-navigation.vue";
import api from "@/api";

export default {
    components: {
        ClientPage,
        SubVisual,
        MyinfoModifyRow,
        MypageNavigation,
    },
    props: {
        tab: { type: String, default: "info" },
    },
    data: function() {
        return {
            leave: "main",

            withdrawalReason: null,
            withdrawalMessage: null,
        };
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        tab() {
            window.scrollTo({ top: 0, behavior: "smooth" });
        },
    },
    methods: {
        init() {
            if (!this.accessToken) {
                alert("로그인 이후 이용가능합니다");
                this.$router.push("/login");
                return;
            }
        },
        async withdraw() {
            await api.v1.me.withdraw({
                withdrawalReason: this.withdrawalReason,
                withdrawalMessage: this.withdrawalMessage,
            });

            alert("탈퇴처리 되었습니다");

            this.$store.dispatch("logout").then(() => {
                this.$router.push("/");
            });
        },
    },
};
</script>

<style scoped>
#app{
    background-color: var(--v-primary-lighten5) !important;
}
</style>
