<template>
    <v-row align="center" justify="space-between">
        <v-col cols="12" md="auto">
            <div class="tit line-height-12 mb-4 mb-md-0">
                {{ user.name }}님의 <br class="d-none d-md-block" />
                <span class="font-size-30 font-size-md-36 font-size-lg-48 primary--text">캘린더</span>
            </div>
        </v-col>
        <v-col cols="12" md="auto">
            <v-row class="my-n6 my-sm-n10 ma-md-n30 ma-lg-n40">
                <v-col v-for="item in items" :key="item.title" cols="6" sm="3" md="auto" class="py-6 py-sm-n10 pa-md-30 pa-lg-40 text-center">
                    <div :class="'font-size-22 font-size-md-30 font-size-lg-56 font-weight-bold ' + item.class">{{ item.count }}</div>
                    <div class="font-size-16 font-size-md-18 font-size-lg-20 font-weight-medium">{{ item.title }}</div>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import api from "@/api";
import { PROGRAM_APPLICATION_STATES, RENTAL_SCHEDULE_STATES } from "@/assets/variables";

export default {
    props: {
        user: { type: Object, default: () => ({}) },
    },
    data: () => ({
        items: [],
    }),
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            let applicationAppliedCount = (await api.v1.me.programs.applications.gets({ _program: undefined, params: { state: PROGRAM_APPLICATION_STATES.APPLIED.value, onlyCount: true } }))?.summary?.totalCount;

            let applicationApprovedCount = (await api.v1.me.programs.applications.gets({ _program: undefined, params: { state: PROGRAM_APPLICATION_STATES.APPROVED.value, onlyCount: true } }))?.summary?.totalCount;

            let scheduleAppliedCount = (await api.v1.me.rentalSchedules.gets({ params: { state: RENTAL_SCHEDULE_STATES.APPLIED.value, onlyCount: true } }))?.summary?.totalCount;

            let scheduleApprovedCount = (await api.v1.me.rentalSchedules.gets({ params: { state: RENTAL_SCHEDULE_STATES.APPROVED.value, onlyCount: true } }))?.summary?.totalCount;

            this.items = [
                { title: "프로그램 승인 대기", count: applicationAppliedCount, class: "warning--text" },
                { title: "프로그램 승인 완료", count: applicationApprovedCount, class: "primary--text" },
                { title: "대관 승인 대기", count: scheduleAppliedCount, class: "warning--text" },
                { title: "대관 승인 완료", count: scheduleApprovedCount, class: "green--text text--lighten-1" },
            ];
        },
    },
};
</script>

<style></style>
