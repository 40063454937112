var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "app--mypage primary lighten-5"
  }, [_c('v-container', [_c('div', {
    staticClass: "tit-wrap text-center"
  }, [_c('h2', {
    staticClass: "tit tit--xl"
  }, [_vm._v("회원탈퇴")])]), _c('v-tabs-items', {
    attrs: {
      "value": _vm.tab
    }
  }, [_c('v-tab-item', {
    attrs: {
      "value": "info"
    }
  }, [_c('v-card', {
    staticClass: "ma-auto",
    attrs: {
      "max-width": "550"
    }
  }, [_c('div', {
    staticClass: "pa-30 px-lg-50 pt-lg-70 pb-lg-60"
  }, [_c('div', {
    staticClass: "tit-wrap mb-12 justify-center"
  }, [_c('h4', {
    staticClass: "tit--sm"
  }, [_vm._v("탈퇴하신 계정은 복구되지 않습니다.")])]), _c('div', {
    staticClass: "mt-20 mt-md-30"
  }, [_c('v-btn', {
    attrs: {
      "block": "",
      "large": "",
      "color": "primary",
      "to": "/mypage/leave/action"
    }
  }, [_vm._v("회원탈퇴")])], 1)])])], 1), _c('v-tab-item', {
    attrs: {
      "value": "action"
    }
  }, [_c('div', {
    staticClass: "tit-wrap mb-12"
  }, [_c('h3', {
    staticClass: "tit--sm"
  }, [_vm._v("탈퇴정보")])]), _c('div', {
    staticClass: "table-style table-style--default"
  }, [_c('myinfo-modify-row', {
    attrs: {
      "label": "탈퇴 사유"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.withdrawalReason,
      callback: function ($$v) {
        _vm.withdrawalReason = $$v;
      },
      expression: "withdrawalReason"
    }
  })], 1), _c('myinfo-modify-row', {
    attrs: {
      "label": "남기실 말씀"
    }
  }, [_c('v-textarea', {
    attrs: {
      "outlined": "",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.withdrawalMessage,
      callback: function ($$v) {
        _vm.withdrawalMessage = $$v;
      },
      expression: "withdrawalMessage"
    }
  })], 1)], 1), _c('div', {
    staticClass: "v-btn-group--bottom"
  }, [_c('v-row', {
    staticClass: "row--small",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-md-240px",
    attrs: {
      "large": "",
      "outlined": "",
      "block": "",
      "exact": "",
      "color": "grey-d6",
      "to": "/mypage/leave"
    }
  }, [_vm._v("취소")])], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-md-240px",
    attrs: {
      "large": "",
      "block": "",
      "color": "primary"
    },
    on: {
      "click": _vm.withdraw
    }
  }, [_vm._v("탈퇴신청")])], 1)], 1)], 1)])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }