var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "align": "center",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "tit line-height-12 mb-4 mb-md-0"
  }, [_vm._v(" " + _vm._s(_vm.user.name) + "님의 "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _c('span', {
    staticClass: "font-size-30 font-size-md-36 font-size-lg-48 primary--text"
  }, [_vm._v("캘린더")])])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-row', {
    staticClass: "my-n6 my-sm-n10 ma-md-n30 ma-lg-n40"
  }, _vm._l(_vm.items, function (item) {
    return _c('v-col', {
      key: item.title,
      staticClass: "py-6 py-sm-n10 pa-md-30 pa-lg-40 text-center",
      attrs: {
        "cols": "6",
        "sm": "3",
        "md": "auto"
      }
    }, [_c('div', {
      class: 'font-size-22 font-size-md-30 font-size-lg-56 font-weight-bold ' + item.class
    }, [_vm._v(_vm._s(item.count))]), _c('div', {
      staticClass: "font-size-16 font-size-md-18 font-size-lg-20 font-weight-medium"
    }, [_vm._v(_vm._s(item.title))])]);
  }), 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }