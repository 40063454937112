var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "app--mypage"
  }, [_c('page-section', {
    staticClass: "page-section--first page-section--py primary lighten-5"
  }, [_c('dashboard-header', _vm._b({}, 'dashboard-header', {
    user: _vm.user
  }, false))], 1), _c('page-section', {
    staticClass: "page-section--last"
  }, [_c('dashboard-calendar', _vm._b({}, 'dashboard-calendar', {
    user: _vm.user
  }, false))], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }