<template>
    <client-page>
        <template v-slot:subHead>
            <sub-visual title="마이페이지" src="/images/sub/sv-mypage.jpg"></sub-visual>
        </template>
        <template v-slot:contentsImmersive>
            <mypage-navigation-row />
        </template>

        <v-container>
            <v-data-table v-bind="{ items, headers }" hide-default-footer disable-filtering disable-sort class="v-data-table--default">
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                <template #[`item.state`]="{ item }">
                    <v-chip x-small label dark :color="stateColor(item)" class="w-80px w-md-100px w-lg-120px">{{ stateText(item) }}</v-chip>
                </template>
                <template #[`item.cancel`]="{ item }">
                    <v-btn :disabled="!isCancelable(item)" x-small color="pink lighten-1" class="w-80px w-md-100px w-lg-120px" @click="cancel(item)">신청취소</v-btn>
                </template>
                <template #footer> <pagination-component :value="page" :count="pageCount" @input="(page) => $router.push({ query: { page } })" /> </template>
            </v-data-table>
        </v-container>
    </client-page>
</template>

<script>
import api from "@/api";
import dayjs from "dayjs";
import { RENTAL_SCHEDULE_STATES } from "@/assets/variables";

import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import MypageNavigationRow from "@/components/client/mypage-navigation-row.vue";
import PaginationComponent from "@/components/client/control/pagination-component.vue";


let headers = [
    { width: "5%", align: "center", text: "번호", value: "index" },
    { width: "14%", align: "center", text: "신청일시", value: "createdAt", formatter: (value) => value?.toDateTime?.() || value },
    { width: "auto", align: "center", text: "지점명", value: "space.branch" },
    { width: "auto", align: "center", text: "공간명", value: "space.name" },
    { width: "14%", align: "center", text: "예약일", value: "date", formatter: (value) => value?.toDate?.() || value },
    { width: "12%", align: "center", text: "예약시간", value: "time", formatter: value=> `${value}시` },
    { width: "10%", align: "center", text: "신청", value: "state" },
    { width: "10%", align: "center", text: "취소", value: "cancel" },
];

export default {
    components: {
        ClientPage,
        SubVisual,
        MypageNavigationRow,
        PaginationComponent,
    },
    data: () => ({
        limit: 10,

        summary: { totalCount: 0 },
        schedules: [],

        headers,
        RENTAL_SCHEDULE_STATES,
    }),
    computed: {
        items() {
            return this.schedules.map((item, index) => ({ ...item, index: this.summary.totalCount - (this.page - 1) * this.limit - index, isDone: dayjs().isAfter(item.datetime) }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
    },
    mounted() {
        this.reroute().then(this.search);
    },
    watch: {
        page() {
            this.search();
        },
    },
    methods: {
        async reroute() {
            if (!this.$store.state.accessToken) this.$router.push("/login");
        },
        async search() {
            let { skip, limit } = this;
            let { summary, schedules } = await api.v1.me.rentalSchedules.gets({ headers: { skip, limit } });

            this.summary = summary;
            this.schedules = schedules;
        },
        async cancel({ _id, space }) {
            let go = confirm(`아래 예약에 대한 신청을 취소하시겠습니까?\n\n지점명: ${space?.branch}\n\n공간명: ${space?.name}`);
            if (go) {
                let { schedule } = await api.v1.me.rentalSchedules.putCancel({ _id });
                this.update(schedule);
            }
        },
        update(item) {
            let index = this.schedules.findIndex(({ _id }) => _id == item._id);
            if (index > -1) {
                this.schedules.splice(index, 1, item);
            } else {
                this.schedules.splice(0, 0, item);
            }
        },
        stateColor({ state, isDone }) {
            if (state == RENTAL_SCHEDULE_STATES.APPROVED.value && isDone) return "grey";
            switch (state) {
                case RENTAL_SCHEDULE_STATES.APPLIED.value:
                    return "accent";
                case RENTAL_SCHEDULE_STATES.CANCELED.value:
                    return "secondary";
                case RENTAL_SCHEDULE_STATES.APPROVED.value:
                    return "primary";
                case RENTAL_SCHEDULE_STATES.REJECTED.value:
                    return "pink lighten-1";
            }
        },
        stateText({ state, isDone }) {
            if (state == RENTAL_SCHEDULE_STATES.APPROVED.value && isDone) return "종료";

            switch (state) {
                case RENTAL_SCHEDULE_STATES.APPLIED.value:
                case RENTAL_SCHEDULE_STATES.CANCELED.value:
                case RENTAL_SCHEDULE_STATES.REJECTED.value:
                case RENTAL_SCHEDULE_STATES.APPROVED.value:
                    return RENTAL_SCHEDULE_STATES[state]?.text;
            }
        },
        isCancelable(item) {
            if (item.state == RENTAL_SCHEDULE_STATES.CANCELED.value) return false;
            if (item.state == RENTAL_SCHEDULE_STATES.REJECTED.value) return false;
            if (dayjs().isAfter(item.datetime)) return false;
            return true;
        },
        isAttendable(item) {
            return item.state == RENTAL_SCHEDULE_STATES.APPROVED.value;
        },
    },
};
</script>
