<template>
    <client-page>
        <template v-slot:subHead>
            <sub-visual title="마이페이지" src="/images/sub/sv-mypage.jpg"></sub-visual>
        </template>
        <template v-slot:contentsImmersive>
            <mypage-navigation-row />
        </template>

        <v-container>
            <program-view-card v-bind="{ program }" hideButton />

            <div class="tit-wrap--dot mb-0"><h2 class="tit">행사일정</h2></div>
            <v-divider class="my-14 my-md-24" />
            <program-view-attendances v-bind="{ program }" />

            <div class="mt-12 mt-md-22">
                <v-row justify="end" class="row--x-small">
                    <v-col cols="auto">
                        <v-btn outlined color="grey-d6" class="w-140px" @click="$router.go(-1)">목록</v-btn>
                    </v-col>
                </v-row>
            </div>
        </v-container>
    </client-page>
</template>

<script>
import api from "@/api";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import ProgramViewCard from "@/components/client/program/program-view-card.vue";
import MypageNavigationRow from "@/components/client/mypage-navigation-row.vue";
import ProgramViewAttendances from "@/components/client/program/program-view-attendances.vue";

export default {
    components: {
        SubVisual,
        ClientPage,
        ProgramViewCard,
        MypageNavigationRow,
        ProgramViewAttendances,
    },
    props: {
        _program: { type: String, default: null },
    },
    data() {
        return {
            program: null,
        };
    },
    mounted() {
        this.reroute().then(this.init);
    },
    methods: {
        async reroute() {
            if (!this.$store.state.accessToken) this.$router.push("/login");
        },
        async init() {
            let { program } = await api.v1.programs.get({ _id: this._program });
            this.program = program;
        },
    },
};
</script>
