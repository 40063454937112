<template>
	<client-page class="app--mypage">
        <template v-slot:subHead>
            <sub-visual title="마이페이지" src="/images/sub/sv-mypage.jpg"></sub-visual>
        </template>
        <template v-slot:contentsImmersive>
            <mypage-navigation-row />
        </template>

        <v-container>
            <router-view name="question"></router-view>
        </v-container>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import MypageNavigationRow from "@/components/client/mypage-navigation-row.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        MypageNavigationRow,
    },
    mounted() {
        this.init();
    },
    methods: {
        init: function() {
            if (!this.accessToken) {
                this.$router.push("/login");
            }
        },
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
        payload() {
            return this.$store.state.payload || {};
        },
        scope() {
            return this.payload.scope || [];
        },
    },
};
</script>
