<template>
    <v-row class="row--small row--dashboard-header-navigation">
        <v-col v-for="item in items" :key="item.to" cols="12" sm="6">
            <v-card height="100%" :to="item.to">
                <div class="d-flex flex-column h-100 pa-20 px-md-36 py-md-30">
                    <div class="mb-4">
                        <v-row align="center" class="row--x-small">
                            <v-col cols="auto"><div class="tit tit--sm">{{ item.text }}</div></v-col>
                            <v-col cols="auto"><i class="icon icon-chevron-right-sm"></i></v-col>
                        </v-row>
                    </div>
                    <v-img v-bind="item" class="mt-auto ml-auto" />
                </div>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
const items = [
    { text: "프로그램", to: "/mypage/program-applications", src: "/images/icon/icon-calendar-lg.png", maxHeight: "60" },
    { text: "대관예약 현황", to: "/mypage/rental-schedules", src: "/images/icon/icon-place-lg.png", maxHeight: "60" },
    { text: "내정보", to: "/mypage/myinfo", src: "/images/icon/icon-users-lg.png", maxHeight: "60" },
    { text: "1:1문의", to: "/mypage/question", src: "/images/icon/icon-chat-lg.png", maxHeight: "56" },
];
export default {
    data: () => ({
        items,
    }),
};
</script>

<style scoped>
.row--dashboard-header-navigation{
    height: calc(100% + 12px);
}
@media (min-width:576px){
}
@media (min-width:768px){
    .row--dashboard-header-navigation{
        height: calc(100% + 16px);
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}
</style>