<template>
    <navigation-row v-bind="{ items }" />
</template>

<script>
import NavigationRow from "./dumb/navigation-row.vue";
const items = [
    { to: "/mypage/dashboard", text: "대시보드" },
    { to: "/mypage/program-applications", text: "프로그램", alternate: "/mypage/programs" },
    { to: "/mypage/rental-schedules", text: "대관예약" },
    { to: "/mypage/question", text: "1:1문의" },
    { to: "/mypage/myinfo", text: "내정보" },
];
export default {
    components: {
        NavigationRow,
    },
    data: () => ({
        items,
    }),
};
</script>

<style></style>
