<template>
    <client-page class="app--mypage">
        <template v-slot:subHead>
            <sub-visual title="마이페이지" src="/images/sub/sv-mypage.jpg"></sub-visual>
        </template>
        <template v-slot:contentsImmersive>
            <div class="sub-tab-wrap">
                <mypage-navigation-row />
            </div>
        </template>

        <v-container>
            <div v-if="me">
                <v-tabs-items :value="tab">
                    <v-tab-item value="view">
                        <div class="table-style table-style--default">
                            <myinfo-row label="아이디"> {{ me.username }} </myinfo-row>

                            <template v-if="me.type == PERSON.value">
                                <myinfo-row label="이름"> {{ me.name }} </myinfo-row>
                                <myinfo-row label="성별"> {{ me.gender || "-" }} </myinfo-row>
                                <myinfo-row label="생년월일"> {{ me.birthday?.toDate?.() || me.birthday || "-" }} </myinfo-row>
                                <myinfo-row label="연락처"> {{ me.phone }} </myinfo-row>
                                <myinfo-row label="이메일"> {{ me.email }} </myinfo-row>
                                <myinfo-row label="주소"> [{{ me.postcode }}] {{ me.address1 }} {{ me.address2 }} </myinfo-row>
                                <myinfo-row label="관심키워드"><b class="primary--text">{{ keywords }}</b></myinfo-row>
                            </template>
                        </div>
                        <div class="mt-12 mt-md-22">
                            <v-row justify="end" class="row--x-small">
                                <v-col cols="auto">
                                    <v-btn outlined color="grey-d6" class="w-140px" to="/mypage/leave">회원탈퇴</v-btn>
                                </v-col>
                            </v-row>
                        </div>

                        <!-- 하단버튼 -->
                        <div class="v-btn-group--bottom">
                            <v-row justify="center" class="row--small">
                                <v-col cols="6" sm="auto">
                                    <v-btn large block color="primary" class="w-md-240px" @click="goModify">수정</v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </v-tab-item>

                    <v-tab-item value="modify">
                        <div class="table-style table-style--default">
                            <myinfo-modify-row label="아이디"> {{ me.username }} </myinfo-modify-row>
                            <myinfo-modify-row label="비밀번호" pointer>
                                <v-text-field v-model="me.password" type="password" name="password" outlined hide-details></v-text-field>
                                <div class="font-size-14 mt-4" style="color:#ff0000;">(영문대소문자/숫자/특수문자 중 2가지 이상 조합, 8 - 16자)</div>
                            </myinfo-modify-row>
                            <myinfo-modify-row label="비밀번호 확인" pointer>
                                <v-text-field v-model="me.password2" type="password" name="" value="" outlined hide-details placeholder="비밀번호를 재입력하세요." />
                            </myinfo-modify-row>

                            <template v-if="me.type == PERSON.value">
                                <myinfo-modify-row label="이름">
                                    <v-text-field v-model="me.name" type="text" outlined hide-details />
                                </myinfo-modify-row>
                                <myinfo-modify-row label="성별">
                                    <v-radio-group v-model="me.gender" row hide-details>
                                        <v-radio label="여성" value="여성" />
                                        <v-radio label="남성" value="남성" />
                                        <!-- <v-radio label="선택하지 않음" :value="null" /> -->
                                    </v-radio-group>
                                </myinfo-modify-row>
                                <myinfo-modify-row label="생년월일">
                                    <v-date-select v-model="me.birthday" outlined hide-details />
                                </myinfo-modify-row>
                                <myinfo-modify-row label="연락처" pointer>
                                    <v-phone-field v-model="me" outlined hide-details />
                                </myinfo-modify-row>
                                <myinfo-modify-row label="이메일" pointer>
                                    <v-email-field v-model="me.email" outlined hide-details />
                                </myinfo-modify-row>
                                <myinfo-modify-row label="주소" pointer>
                                    <v-address-field v-model="me" outlined hide-details />
                                </myinfo-modify-row>
                                <myinfo-modify-row label="관심키워드" pointer>
                                    <autocomplete-program-keywords v-model="me._keywords" outlined hide-details />
                                </myinfo-modify-row>
                            </template>
                        </div>

                        <!-- 하단버튼 -->
                        <div class="v-btn-group--bottom">
                            <v-row justify="center" class="row--small">
                                <v-col cols="6" sm="auto">
                                    <v-btn large outlined block color="grey-d6" class="w-md-240px" @click="goView()">취소</v-btn>
                                </v-col>
                                <v-col cols="6" sm="auto">
                                    <v-btn large block color="primary" class="w-md-240px" @click="save">저장</v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </v-container>
    </client-page>
</template>

<script>
import api from "@/api";
import CryptoAES from "@/plugins/crypto-aes";
import { USER_NOTIFY_METHODS, USER_TYPES } from "@/assets/variables";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import MyinfoRow from "@/components/client/mypage/myinfo-row.vue";
import VEmailField from "@/components/client/dumb/v-email-field.vue";
import VPhoneField from "@/components/client/dumb/v-phone-field.vue";
import VAddressField from "@/components/client/dumb/v-address-field.vue";
import MyinfoModifyRow from "@/components/client/mypage/myinfo-modify-row.vue";
import MypageNavigationRow from "@/components/client/mypage-navigation-row.vue";
import AutocompleteProgramKeywords from "@/components/dumb/autocomplete-program-keywords.vue";
import VDateSelect from '@/components/client/dumb/v-date-select.vue';

const { PERSON } = USER_TYPES;

export default {
    components: {
        ClientPage,
        SubVisual,
        MyinfoRow,
        VEmailField,
        VPhoneField,
        VAddressField,
        MyinfoModifyRow,
        MypageNavigationRow,
        AutocompleteProgramKeywords,
        VDateSelect,
    },
    props: {
        tab: { type: String, default: "view" },
    },
    data() {
        return {
            me: null,

            window,
            PERSON,
        };
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
        payload() {
            return this.$store.state.payload || {};
        },
        scope() {
            return this.payload.scope || [];
        },
        keywords() {
            return (this.me?.keywords || []).map(({ name }) => name).join(", ") || "-";
        },
    },
    mounted() {
        this.init();
    },
    watch: {},
    methods: {
        async init() {
            try {
                if (!this.accessToken) {
                    this.$router.push({
                        path: "/login",
                        params: this.$router.params,
                    });
                    return;
                }

                var { user } = await api.v1.me.get();
                if (user?.birthday) user.birthday = user.birthday.toDate();

                this.me = user;
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        checkPassword(password) {
            var checkSpecial = /[~!@#$%^&*()_+|<>?:{}]/;
            var checkEng = /[a-zA-Z]/;
            var checkNum = /[0-9]/;

            if (!checkSpecial.test(password)) throw new Error("비밀번호에 특수문자가 포함되어있어야합니다.");
            if (!checkEng.test(password)) throw new Error("비밀번호에 영문이 포함되어있어야합니다.");
            if (!checkNum.test(password)) throw new Error("비밀번호에 숫자가 포함되어있어야합니다.");
            if (password.length < 8 || 16 < password.length) throw new Error("비밀번호는 8~16자까지입니다.");
        },

        validate() {
            try {
                if ((this.me.password || this.me.password2) && this.me.password != this.me.password2) throw new Error("비밀번호 및 비밀번호확인이 일치하지 않습니다");
                if (this.me.password && this.me.password2) this.checkPassword(this.me.password);

                return true;
            } catch (error) {
                alert(error.message);
            }
            return false;
        },

        async save() {
            if (this.validate()) {
                await api.v1.me.put({
                    ...this.me,
                    password: this.me.password ? CryptoAES.encrypt(this.me.password) : undefined,
                    password2: undefined,
                });

                alert("수정되었습니다");

                this.init();
                this.goView();
            }
        },

        goTop() {
            window.scrollTo({ top: 400, behavior: "smooth" });
        },
        goModify() {
            this.goTop();
            this.$router.push("/mypage/myinfo/modify");
        },
        goView() {
            this.goTop();
            this.$router.push("/mypage/myinfo/view");
        },
        getNotifyName({ method }) {
            return Object.values(USER_NOTIFY_METHODS).find(({ value }) => value == method)?.text;
        },
    },
};
</script>
